<template>
  <a-modal width="60%" :label-col="4" :wrapper-col="14" :visible="open" @cancel="onClose" :destroyOnClose="true"  @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form"  :label-col="labelCol" :wrapper-col="wrapperCol" :model="form" :rules="rules">
      <a-form-model-item label="名称" prop="advertName" >
        <a-input v-model="form.advertName" :maxLength="16" placeholder="请输入名称" />
      </a-form-model-item>
      <a-form-model-item label="分类" prop="categoryId" >
        <a-select placeholder="请选择分类" style="width: 100%" v-model="form.categoryId" allowClear>
          <a-select-option v-for="category in categoryList" :value="category.id">
            {{category.name}}
          </a-select-option>
        </a-select>
<!--        <template>
          <a-cascader :options="categoryList"
                      :field-names="{ label: 'name', value: 'id', children: 'children'}" placeholder="请选择分类"
                       style="width: 100%" v-model="categorySelected" allowClear @change="onCategoryChange">
          </a-cascader>
        </template>-->
      </a-form-model-item>
      <a-form-model-item label="简介" prop="advertInfo" >
        <a-input v-model="form.advertInfo" :maxLength="32" placeholder="请输入简介" />
      </a-form-model-item>
      <a-form-model-item label="周价格" prop="weekAmount" >
        <a-input v-model="form.weekAmount" placeholder="请输入周价格" />
      </a-form-model-item>
      <a-form-model-item label="月价格" prop="monthAmount" >
        <a-input v-model="form.monthAmount" placeholder="请输入月价格" />
      </a-form-model-item>
      <a-form-model-item label="年价格" prop="yearAmount" >
        <a-input v-model="form.yearAmount" placeholder="请输入年价格" />
      </a-form-model-item>
      <a-form-item label="规格" :label-col="{ span: 4 }" :wrapper-col="{ span: 20 }" class="margin-bottom-10">
        <div class="margin-bottom-10">
          <a-button type="primary" @click='addTime'>
            添加规格
          </a-button>
        </div>
        <a-table :data-source="form.specList" :columns="specColumns" rowKey="id" :pagination="false" bordered>
          <div slot="spec" slot-scope="text, record">
            <a-input v-model="record.spec" :maxLength="16" placeholder="请输入规格" />
          </div>
          <div slot="stock" slot-scope="text, record">
            <a-input v-model="record.stock" placeholder="请输入库存" />
          </div>
          <div slot="action" slot-scope="text, record">
            <a-popconfirm title="确定要删除此规格吗？" ok-text="确定" cancel-text="取消" @confirm="delTime(record)">
              <a href="javascript:void(0)" style="color:red;">删除</a>
            </a-popconfirm>
          </div>
        </a-table>
      </a-form-item>

      <a-form-model-item label="是否上架" prop="isSale" >
        <a-radio-group v-model="form.isSale">
          <a-radio v-for="(item, index) in this.customDict.TFEnum" :value="item.type" :key="index">
            {{ item.name }}
          </a-radio>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item label="主图" prop="masterImg">
        <a-upload
          name="masterImg"
          listType="picture-card"
          class="avatar-uploader"
          :multiple="false"
          :show-upload-list="false"
          :preview="handlePreview"
          :before-upload="beforeOssUpload"
          :customRequest="coverImgUpload">
          <img
            v-if="form.masterImg"
            :src="form.masterImg"
            alt="masterImg"
            style="height: 140px; width: 140px;"/>
          <div v-else>
            <a-icon :type="loading ? 'loading' : 'plus'"/>
            <div class="ant-upload-text">上传</div>
          </div>
        </a-upload>
      </a-form-model-item>
      <a-form-model-item label="详情(多图)" prop="advertImgList">
        <a-upload
          name="advertImgList"
          v-model="fileList"
          listType="picture-card"
          :fileList="fileList"
          :remove="removeFile"
          class="avatar-uploader"
          :multiple="false"
          :show-upload-list="true"
          :preview="handlePreview"
          :before-upload="beforeOssUpload"
          :customRequest="coverAvatarUploadInfo">
          <div>
            <a-icon :type="loading ? 'loading' : 'plus'"/>
            <div class="ant-upload-text">上传</div>
          </div>
        </a-upload>
      </a-form-model-item>
      <a-form-model-item label="视频案例" prop="imgCaseList" >
        <a-upload
          name="imgCaseList"
          v-model="imgCaseList"
          listType="picture-card"
          :fileList="imgCaseList"
          :remove="removeImgCaseFile"
          class="avatar-uploader"
          :multiple="false"
          :show-upload-list="true"
          :preview="handlePreview"
          :before-upload="beforeOssUpload"
          :customRequest="coverImgCaseUploadInfo">
          <div>
            <a-icon :type="loading ? 'loading' : 'plus'"/>
            <div class="ant-upload-text">上传</div>
          </div>
        </a-upload>
      </a-form-model-item>
      <a-form-model-item label="投放规则" prop="rule" >
        <a-input v-model="form.rule" placeholder="请输入内容" type="textarea" allow-clear />
      </a-form-model-item>
      <a-form-item prop="advertDesc" label="广告详情" :label-col="{ span: 4 }" :wrapper-col="{ span: 20 }">
        <editor v-model="form.advertDesc" placeholder="请输入广告详情" />
      </a-form-item>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-modal>
</template>

<script>
import { getAdvert, addAdvert, updateAdvert } from '@/api/res/advert'
import {mapGetters} from 'vuex'
import CustomDictTag from "@/components/DictCustomTag";
import {advertCategory, listCategory} from "@/api/res/category";
import Editor from '@/components/Editor'
import {generateFilePath, uploadObject} from "@/api/tool/alioss";
import {fileUpload} from "@/api/tool/common";

export default {
  name: 'CreateForm',
  props: {
  },
  components: {
    Editor,
    CustomDictTag,
  },
  data () {
    return {
      specColumns: [
        {
          title: '序号',
          dataIndex: 'index',
          key: 'index'
        }, {
          title: '规格名',
          dataIndex: 'spec',
          key: 'spec',
          scopedSlots: { customRender: 'spec' }
        }, {
          title: '库存',
          dataIndex: 'stock',
          key: 'stock',
          scopedSlots: { customRender: 'stock' }
        }, {
          title: '操作',
          dataIndex: 'action',
          key: 'action',
          slots: { title: 'addbtn', customRender: 'name' },
          scopedSlots: { customRender: 'action' }
        }
      ],
      count: 0,
      categorySelected :[],
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      submitLoading: false,
      formTitle: '',
      loading: '',
      categoryList:null,
      fileList: [],
      imgCaseList: [],
      // 表单参数
      form: {
        id: null,
        masterImg: null,
        advertName: null,
        advertInfo: null,
        specList: [],
        categoryId: null,
        advertImgList: [],
        advertDesc: null,
        imgCaseList: [],
        advertImg: null,


        weekAmount: null,

        monthAmount: null,

        yearAmount: null,

        rule: null,


        isSale: null,

        createTime: null,

      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        advertName: [
          { required: true, message: '名称不能为空', trigger: 'blur' }
        ],
        categoryId: [
          { required: true, message: '分类不能为空', trigger: 'blur' }
        ],
        advertInfo: [
          { required: true, message: '简介不能为空', trigger: 'blur' }
        ],

      }
    }
  },
  filters: {
  },
  created () {
    this.getCategoryList()
  },
  computed: {
    ...mapGetters(['customDict'])
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    onClose () {
      this.open = false
      this.reset()
    },
    addTime() {
      const index = this.form.specList.length+1;
      const newData = {
        index: index,
        spec: null,
        stock: 0
      };
      this.form.specList.push(newData);
      this.count = index;
    },
    /**
     * 删除等级
     */
    delTime(info) {
      this.form.specList = this.form.specList.filter(item => item.index !== info.index);
    },

    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    getCategoryList () {
      advertCategory().then(response => {
        this.categoryList = response.data
      })
    },
    onCategoryChange (value, selectedOptions) {
      this.form.categoryId = value[1]
    },
    startTimeChange(date, dateString){
      this.form.startTime = dateString
    },
    endTimeChange(date, dateString){
      this.form.endTime = dateString
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.fileList= []
      this.imgCaseList=[]
      this.form = {
        id: null,
        masterImg: null,
        advertName: null,
        advertInfo: null,
        specList: [],
        categoryId: null,
        advertImgList: [],
        advertDesc: null,
        imgCaseList: [],
        advertImg: null,
        weekAmount: null,
        monthAmount: null,
        yearAmount: null,
        rule: null,
        isSale: null,
        createTime: null,
      }
    },
    // oss 上传开始
    async handlePreview(file) {
      if (!file.url && !file.preview) {
        file.preview = await this.getBase64(file.originFileObj)
      }
      this.previewImage = file.url || file.preview
      this.previewVisible = true
    },
    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => resolve(reader.result)
        reader.onerror = error => reject(error)
      })
    },
    // 上传之前校验
    beforeOssUpload(file) {
      // const isJPG = file.type === 'image/jpeg';
      const isLt2M = file.size / 1024 / 1024 < 20
      // if (!isJPG) {
      //     this.$message.error('上传头像图片只能是 JPG 格式!');
      // }
      if (!isLt2M) {
        this.$message.error('上传图片大小不能超过 20MB!')
      }
      return isLt2M
    },
    // 上传覆盖默认的上传行为
    coverImgUpload(file, index) {
      const _this = this
      // 获取文件对象
      const uploadFile = file.file
      // 文件夹目录
      const prefix = 'advert'
      const url = _this.uploadAliYunOss(uploadFile.name, prefix)
      /*_this.$nextTick(() => {
        uploadObject(url, uploadFile).then(res => {
          _this.form.masterImg = res
          _this.$message.success('上传成功')
        })
      })*/
      const formData = new FormData()
      formData.append('file', uploadFile, url)
      _this.$nextTick(() => {
        fileUpload(formData).then(res => {
          _this.form.masterImg = res.data.fullPath
          _this.$message.success('上传成功')
        })
      })
    },
    /**
     * 文件上传到oss返回url地址
     * @param { file } 文件对象
     */
    uploadAliYunOss(fileName, prefix) {
      // 获取文件后缀
      const suffix = fileName.substring(fileName.lastIndexOf('.'))
      // 生成文件名称
      const filePath = generateFilePath(prefix, suffix)
      return filePath
    },
    // oss 上传结束

    //多图上传开始
    // 上传详情覆盖默认的上传行为
    coverAvatarUploadInfo(file, index) {
      const _this = this
      // 获取文件对象
      const uploadFile = file.file
      // 文件夹目录
      const prefix = 'advert'
      const url = _this.uploadAliYunOss(uploadFile.name, prefix)
      const formData = new FormData()
      formData.append('file', uploadFile, url)
      _this.$nextTick(() => {
        fileUpload(formData).then(res => {
          _this.fileList.push({status: 'done', url: res.data.fullPath, uid: this.getUidRandom(), name: 'image.jpg'})
          this.form.advertImgList= []
          _this.fileList.forEach(e => {
            if (e.url !== undefined && e.url !== null && e.url !== '') {
              this.form.advertImgList.push(e.url);
            }
          })
          _this.$message.success('上传成功')
        })
      })
    },
    // 删除图片
    removeFile(file) {
      var fileList = []
      this.fileList.forEach(e => {
        if (e.url !== file.url) {
          fileList.push(e)
        }
      })
      var advertImgList = []
      this.fileList = fileList
      fileList.forEach(e => {
        advertImgList.push(e.url)
      })
      this.form.advertImgList = advertImgList
    },
    getUidRandom() {
      return Math.round(Math.random() * 80 + 20)
    },
    //多图上传结束

    //多图上传开始
    // 上传详情覆盖默认的上传行为
    coverImgCaseUploadInfo(file, index) {
      const _this = this
      // 获取文件对象
      const uploadFile = file.file
      // 文件夹目录
      const prefix = 'advert'
      const url = _this.uploadAliYunOss(uploadFile.name, prefix)
      const formData = new FormData()
      formData.append('file', uploadFile, url)
      _this.$nextTick(() => {
        fileUpload(formData).then(res => {
          _this.imgCaseList.push({status: 'done', url: res.data.fullPath, uid: this.getUidRandom(), name: 'image.jpg'})
          this.form.imgCaseList= []
          _this.imgCaseList.forEach(e => {
            if (e.url !== undefined && e.url !== null && e.url !== '') {
              this.form.imgCaseList.push(e.url);
            }
          })
          _this.$message.success('上传成功')
        })
      })
    },
    // 删除图片
    removeImgCaseFile(file) {
      var fileList = []
      this.imgCaseList.forEach(e => {
        if (e.url !== file.url) {
          fileList.push(e)
        }
      })
      var imgCaseList = []
      this.imgCaseList = fileList
      fileList.forEach(e => {
        imgCaseList.push(e.url)
      })
      this.form.imgCaseList = imgCaseList
    },
    //多图上传结束

    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getAdvert({"id":id}).then(response => {
        this.form = response.data;
        let idx = 1;
        this.form.specList.forEach(e=>{
          e.index= idx;
          idx++;
        })
        this.fileList =[];
        var advertImgList = this.form.advertImgList ==null ? [] : this.form.advertImgList;
        for (var i = 0; i < advertImgList.length; i++) {
          this.fileList.push({status: 'done', url: advertImgList[i], uid: this.getUidRandom(), name: 'image.jpg'})
        }
        this.imgCaseList =[];
        var imgCaseList = this.form.imgCaseList ==null ? [] : this.form.imgCaseList;
        for (var j = 0; j < imgCaseList.length; j++) {
          this.imgCaseList.push({status: 'done', url: imgCaseList[j], uid: this.getUidRandom(), name: 'image.jpg'})
        }
        //回显出分类
        this.categoryList.forEach(e => {
          if (e.children) {
            e.children.forEach(child => {
              if (child.id === this.form.categoryId) {
                this.categorySelected.push(e.id)
                this.categorySelected.push(child.id)
              }
            });
          }
        });
        this.open = true
        this.formTitle = '修改'
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id !== undefined && this.form.id !== null) {
            updateAdvert(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addAdvert(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
