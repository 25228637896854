import request from '@/utils/request'


// 查询res-广告列表
export function listAdvert(query) {
  return request({
    url: '/res/advert/list',
    method: 'get',
    params: query
  })
}

// 查询res-广告分页
export function pageAdvert(query) {
  return request({
    url: '/res/advert/page',
    method: 'get',
    params: query
  })
}

// 查询res-广告详细
export function getAdvert(data) {
  return request({
    url: '/res/advert/detail',
    method: 'get',
    params: data
  })
}

// 查询res-库存
export function getStock(data) {
  return request({
    url: '/res/advert/stock',
    method: 'post',
    data: data
  })
}


// 新增res-广告
export function addAdvert(data) {
  return request({
    url: '/res/advert/add',
    method: 'post',
    data: data
  })
}

// 修改res-广告
export function updateAdvert(data) {
  return request({
    url: '/res/advert/edit',
    method: 'post',
    data: data
  })
}

// 删除res-广告
export function delAdvert(data) {
  return request({
    url: '/res/advert/delete',
    method: 'post',
    data: data
  })
}
