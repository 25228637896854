import request from '@/utils/request'


// 查询res-分类列表
export function listCategory(query) {
  return request({
    url: '/res/category/list',
    method: 'get',
    params: query
  })
}

export function advertCategory(query) {
  return request({
    url: '/res/category/list/advert',
    method: 'get',
    params: query
  })
}

// 查询res-分类分页
export function pageCategory(query) {
  return request({
    url: '/res/category/page',
    method: 'get',
    params: query
  })
}

// 查询res-分类详细
export function getCategory(data) {
  return request({
    url: '/res/category/detail',
    method: 'get',
    params: data
  })
}

// 新增res-分类
export function addCategory(data) {
  return request({
    url: '/res/category/add',
    method: 'post',
    data: data
  })
}

// 修改res-分类
export function updateCategory(data) {
  return request({
    url: '/res/category/edit',
    method: 'post',
    data: data
  })
}

// 删除res-分类
export function delCategory(data) {
  return request({
    url: '/res/category/delete',
    method: 'post',
    data: data
  })
}
